import { render, staticRenderFns } from "./Canvas.vue?vue&type=template&id=a1a74aaa&scoped=true"
import script from "./Canvas.vue?vue&type=script&lang=js"
export * from "./Canvas.vue?vue&type=script&lang=js"
import style0 from "./Canvas.vue?vue&type=style&index=0&id=a1a74aaa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1a74aaa",
  null
  
)

export default component.exports
<template>
  <div class="canvas pa3">
    <!-- <div class="w-80" id="calendar-settings">
        <div class="form-group">
            <label for="theme" id="lang_set_theme"></label>
            <select class="form-control" id="theme">
                <option value="0">Dark</option>
                <option value="1">Light</option>
                <option value="2">Red</option>
            </select>
        </div>

        <div class="form-group">
            <div>
                <label for="" id="lang_save_print"></label>
            </div>
            <div class="btn-group">
                <a href="#" class="btn btn-default" id="lang_save_jpg"></a>
                <a href="#" class="btn btn-default" id="lang_save_pdf"></a>
                <a href="#" class="btn btn-default" id="lang_print"></a>
            </div>
        </div>
    </div> -->
    <div class="w-100 tc">
        <div class="f2 ttl tracked b tc"><a class="black link dim" target="_blank" href="https://waitbutwhy.com/2014/05/life-weeks.html">
          <span class="ttd fw2">Reah's</span> Life Calendar</a>
            <!-- <span class="ttd fw2"> [{{ getMetric }}]</span>  -->
        </div>
        <div class="dib f5 ttu tracked tc mt3"><b>Birthday:</b></div>
        <div class="dib f5 ttu tracked tc ml3">
          <birth-datepicker v-model="date" :value="date" v-on:input="update()" :high="true" :minYear="1900"/>
            <!-- <input type="radio" v-model="metric" value="weeks">weeks
            <input type="radio" v-model="metric" value="years">years -->
        </div>
        <div class="f4 center w-80 mt3">
            <div id="calendar-canvas"></div>
        </div>
    </div>
  </div>
</template>

<script>
import birthDatepicker from 'vue-birth-datepicker';
import 'vue-birth-datepicker/dist/vueBirthDatepicker.css';
// import * as CONSTS from '@/consts';
// canvas
const offset = +300; //Timezone offset for EST in minutes.
const _px = 0.5; // correcting pixels
const c = document.createElement('canvas');
const ctx = c.getContext('2d');
const currentDate = new Date();
const BOX_SIZE = 15;
const BOX_MARGIN = 3;
const BOX_SIZE_REAL = BOX_SIZE - BOX_MARGIN;

const TABLE_LEFT = 40;
const TABLE_TOP = 70;

const COUNT_WEEKS = 52;
const COUNT_YEARS = 90;

const CANVAS_WIDTH = 825;
const CANVAS_HEIGHT = 1450;

const DEF_BACKGROUND_COLOR = '#FFF';
let DEF_STROKE_COLOR = '#000';
const DEF_FILL_COLOR = '#000';
let DEF_BOX_COLOR = '#FFF';
let PASTDAY_COLOR = '#01b9ff';

const AXIS_LEFT_TEXT = '← Age';
const AXIS_TOP_TEXT = 'Week of the Year →';

export default {
  name: 'Canvas',
  data() {
    return {
      date: Number(697852800000 + offset * 60 * 1000),
      metric: 'weeks'
    };
  },
  components: { birthDatepicker },
  computed: {
    getMetric() {
      return this.metric;
    }
  },
  mounted() {
    ctx.strokeStyle = DEF_STROKE_COLOR;
    ctx.fillStyle = DEF_FILL_COLOR;
    ctx.font = '13px sans-serif';

    // set ratio
    const ratio = this.calculateRatio(window, ctx);

    if (ratio === 1) {
      c.width = CANVAS_WIDTH;
      c.height = CANVAS_HEIGHT;
    } else {
      c.width = CANVAS_WIDTH * ratio;
      c.height = CANVAS_HEIGHT * ratio;

      c.style.width = `${CANVAS_WIDTH}px`;
      c.style.height = `${CANVAS_HEIGHT}px`;

      ctx.scale(ratio, ratio);
    }

    // dates
    document.getElementById('calendar-canvas').appendChild(c);
    this.resetCanvas();
    this.update();
  },
  methods: {
    update() {
      console.log('UPDATE:', new Date(this.date + offset * 60 * 1000).toDateString())
      // this.date = new Date(_bDate).toDateString() || new Date().toString();

      this.drawTable(COUNT_YEARS, COUNT_WEEKS, this.generateDates());
    },
    changeTheme(theme) {
      DEF_STROKE_COLOR = theme.box.borderColor;
      DEF_BOX_COLOR = theme.box.backgroundDefaultColor;
      PASTDAY_COLOR = theme.box.backgroundPastDayColor;

      drawTable(COUNT_YEARS, COUNT_WEEKS, this.generateDates());
    },
    generateDates() {
      let i; let j; const years = []; let
        weeks;
      for (i = 0; i < COUNT_YEARS; i++) {
        weeks = [];
        for (j = 1; j <= COUNT_WEEKS; j++) {
          const day = this.getDayObj(i, j);
          weeks.push(day);
        }
        years.push(weeks);
      }
      return years;
    },

     getDayObj(year, week) {
      const weeks = year * COUNT_WEEKS + week;
      const date = this.addDays(new Date(this.date + offset * 60 * 1000), weeks * 7);
      return {
        isPast: (currentDate - date) > 0,
      };
    },
     addDays(date, days) {
      const clone = new Date(date.getTime());
      clone.setDate(date.getDate() + days);
      return clone;
    },

    // CANVAS

     resetCanvas() {
      this.clearCanvas();
      this.drawAxis();
      this.drawMetric();
    },

     clearCanvas() {
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      // draw background:
      ctx.fillStyle = DEF_BACKGROUND_COLOR;
      ctx.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      ctx.fillStyle = DEF_FILL_COLOR;
    },
     drawAxis(textLeft, textTop) {
      ctx.textAlign = 'left';
      ctx.font = "15px Arial";


      // left axis
      ctx.save();
      ctx.translate(20, TABLE_TOP + 40);
      ctx.rotate(-Math.PI / 2);
      ctx.translate(-20, -(TABLE_TOP + 40));
      ctx.fillText(AXIS_LEFT_TEXT, 20, TABLE_TOP + 40);
      ctx.restore();

      // top axis
      ctx.fillText(AXIS_TOP_TEXT, TABLE_LEFT, TABLE_TOP - 35);
    },
     drawMetric() {
      // left metric
      ctx.textAlign = 'right';
      for (var i = 0; i < COUNT_YEARS; i++) {
        if (i % 5 === 0) {
          ctx.fillText(i, TABLE_LEFT - 5, TABLE_TOP + 10 + i * BOX_SIZE);
        }
      }

      // top metric
      ctx.textAlign = 'left';
      for (var i = 1; i < COUNT_WEEKS; i++) {
        if (i % 5 === 0 || i === 1) {
          ctx.fillText(i, TABLE_LEFT + (i - 1) * BOX_SIZE, TABLE_TOP - 10);
        }
      }
    },
     drawTable(rows, cols, values) {
       console.log('vallssss', values)
      for (let i = 0; i < rows; i++) {
        this.drawRow(i, cols, values[i]);
      }
    },
     drawRow(row, cols, values) {
      for (let i = 0; i < cols; i++) {
        // color by type
        ctx.fillStyle = values[i].isPast ? PASTDAY_COLOR : DEF_BOX_COLOR;
        ctx.strokeStyle = DEF_STROKE_COLOR;

        this.drawRect(TABLE_LEFT + i * BOX_SIZE, TABLE_TOP + row * BOX_SIZE);
      }
    },
     drawRect(x, y) {
      ctx.fillRect(_px + x, _px + y, BOX_SIZE_REAL, BOX_SIZE_REAL);
      ctx.strokeRect(_px + x, _px + y, BOX_SIZE_REAL, BOX_SIZE_REAL);
    },

     calculateRatio(w, context) {
      const devicePixelRatio = w.devicePixelRatio || 1;
      const backingStoreRatio = context.webkitBackingStorePixelRatio
                || context.mozBackingStorePixelRatio
                || context.msBackingStorePixelRatio
                || context.oBackingStorePixelRatio
                || context.backingStorePixelRatio || 1;
      return devicePixelRatio / backingStoreRatio;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.canvas {
}
</style>

import axios from 'axios'

var store = {
    debug: true,
    state: {
        urls: {}
    },
    addURL (short, url) {
        if (this.debug) console.log('setURLAction triggered')
        this.state.urls[short] = url
    },
    getURLs () {
        if (this.debug) console.log('getURLs triggered')
        return this.state.urls
    },
    initializeState () {
        return new Promise((resolve, reject) => {
            console.log('initialization state triggered')
            let that = this
            axios.get('https://reah.ai/urls')
            .then(function(res) {
                that.state.urls = res.data
                resolve(that.state)
            })
        })
    }
}

export default store
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store.js'
Vue.config.productionTip = false

let vm = new Vue({
	el: '#app',
	template: '<App/>',
	router,
	data: {
		privateState: {},
		sharedState: store.state
	},
	created () {
		console.log('Vue instance created', this.sharedState)
		let that = this
		store.initializeState().then((state) => {
			let urls = state.urls 
			let shortname = that.$route.path.replace('/', '')
			if(urls[shortname]) window.location.href = urls[shortname]
		})
	},
	render: h => h(App)
})

export default vm
<template>
	<div>
		<div class="tc center">
			<iframe src="https://zcal.co/i/E2itVDKi?embed=1&embedType=iframe" loading="lazy" style="border:none; min-width: 320px; min-height: 544px;" :style="{width: this.isMobile() ? '390px':'1005px', height: this.isMobile() ? '900px':'1025px'}" id="zcal-invite"></iframe>
		</div>
			<div class="w-25-ns w-30 center tc f7 fw3 footer pv2-ns pv1">site by me (reah) 🧿</div>
		<router-link to="/" class="w-25 black link">
			<div class="click w-20-ns w-40 center tc f7 fw3 footer pv2-ns pv1 ba mb4">click to go back to home 🏠</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'Coffee',
	data() {
		return {
		}
	},
    methods: { 
		isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true
			} else {
				return false
			}
		}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.click:hover {
	opacity: .8;
	background: aliceblue !important;

}
</style>

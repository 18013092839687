<template>
  <router-link to="/" class="w-100 mt5 f1 fw2 tc pa5-l pa3 black pv4-l mv5-l link">
  	<div>
    	Are you lost?
    	<div class="i mt5">404</div>
    	<div class="mt5">click to go back home</div>
  	</div>
  </router-link>
</template>

<script>
export default {
  name: 'e404',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
<template>
  <div class="words">
    <div class="fw2 f2 w-100 pa3 tc ttu tracked">
      Words
    </div>
    <div class="tc">
      <input class="w-60" v-model="searchText">
    </div>
    <div class="f4 w-100 pa3 tl" v-for="(word, i) in wordsSearched" :key="i">
      <span class="f3 ttu b tracked">{{ word }}</span>
      <br/>
      <div v-for="(def, i) in data[word]" :key="i">
        <div v-if="def.definition != null">
        {{ def.definition }} <br/><br/>
        </div>
        <div v-else>
          <div v-for="(short, i) in def.shortdef" :key="i">
            {{short}} <br/><br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'Words',
    data () {
      return {
        data: '',
        searchText: '',
        words: ''
        }
    },
    mounted: function() {
      let that = this
      axios.get('https://reah.ai/define')
      .then(function(res, err) {
        let data = res.data
        that.words = Object.keys(data)
        that.data = data
      })
    },
    computed : {
      wordsSearched : function(){
        var self = this;
        if( this.searchText == ''){
          return this.words;
        }
        return this.words.filter(function(item){
          return item.indexOf(self.searchText) >= 0;
        });
      }
  },
  }
</script>
<style scoped>
</style>
<template>
	<div>
		<div class="cf"><router-link to='/' class="btn fw4 link mt3 ml3 w-10 fl tc pa3 f3 black">X</router-link></div>
		<div class="center w-100 tc">
			<div class="pb5 f5 fw2">
				<div class="pv3">
		          <span class="f2 fw2">reah</span><span class="f2 fw3 mid-gray">miyara</span>
		        </div>
	        	<a class="link f4 blue" target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=hi@reah.ai">hi<span class="">@reah.ai ✉️ </span></a>
				<div class="mt3 f4-ns f5">Los Angeles / New York / San Francisco / Tel Aviv 📍</div>
			</div>
			<ContactForm></ContactForm>
		</div> 
	</div>
</template>

<script>
import ContactForm from '@/components/ContactForm'
export default {
	name: 'Contact',
	data() {
		return {
		}
	},
    methods: { 
    },
    components: {
    	ContactForm,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn:hover {
	background: #424242;
	color: #fff;
	font-weight: 500;
}
</style>

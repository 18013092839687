<template>
  <div>
    <div class="define f2 fw2 w-100 pa3 tc ttu tracked ">
      {{ word }}
    </div>
    <div v-if="synonyms !== 'looking this up...'"
    class="def pa4 f3-ns f4" >
    <div class="b">Synonyms</div>
      <div v-for="(syn, i) in synonyms" :key="i">
          <span class="ph2" v-for="(w, j) in syn" :key="j">
            <router-link class="black link" :to="{ name: 'Synonym', params: { word: w }}">{{ w }}</router-link>, 
          </span>
      </div>
      <br/>
      <div class="b">Antonyms</div>
      <div v-for="(ant, a) in antonyms" :key="-100 * (a + 1)">
          <span class="ph2" v-for="(w, b) in ant" :key="-1 * (1+b)">
            <router-link class="black link" :to="{ name: 'Synonym', params: { word: w }}">{{ w }}</router-link>, 
          </span>
      </div>
    </div>
    <div v-else class="tc mt5 f4 tracked i">
      looking it up...
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'Define',
    data () {
      return {
        word: this.$route.params.word,
        synonyms: 'looking this up...',
        antonyms: ''
      }
    },
    watch: {
      $route(to, from) {
        console.log('changes')
        this.word = this.$route.params.word
        this.getSyn()
      }
    },
    created: function () {
      this.getSyn()
    },
    methods: {
      getSyn: function() {
        let that = this
        axios.post('https://reah.ai/synonym', {'word':this.word})
        .then(function(res, err) {
          console.log(res.data)
          that.synonyms = res.data.syns
          that.antonyms = res.data.ants
        })
      }
    }
}
</script>
<style scoped>
</style>
<template>
  <div>
    <div class="white define fw2 f2 w-100 pa3 tc ttu tracked ">
      {{ word }}
    </div>
    <div v-if="this.body !== 'looking this up...'" class="def pa4 f3-ns f4" >
      <div v-if="found">
        <div v-for="(entry, i) in body" :key="i">
          <div v-for="(def, i) in entry" :key="i">
              {{def}}
            </div>
          <br/>
        </div>
      </div>
      <div v-else>
        <div class="i f5 tc">did you mean...</div>
        <span v-for="(w, i) in body" :key="i">
          <router-link class="black link mh1" :to="{ name: 'Define', params: { word: w }}">{{ w }},</router-link> 
        </span>
      </div>
    </div>
    <div v-else class="tc mt5 f4 tracked i">
      looking it up...
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'Define',
    data () {
      return {
        word: this.$route.params.word,
        body: 'looking this up...',
        found: true
      }
    },
    watch: {
      $route(to, from) {
        console.log('changes')
        this.word = this.$route.params.word
        this.getDef()
      }
    },
    created: function () {
      this.getDef()
    },
    methods: {
      getDef: function() {
        let that = this
        let defs = []
        axios.post('https://reah.ai/define', {'word':this.word})
        // axios.post('http://localhost:3000/define', {'word':this.word})
        .then(function(res, err) {
          console.log('REAH' , res)
          that.found = (res.data[0] && res.data[0].meta) || (res.data && res.data.data && res.data.data['dup']) ? true : false
          if(that.found) {
            defs = res.data.data.map((def, i) => {
              console.log('def', def)
              return {
                fl: def.fl,
                defin: def.shortdef
              }
            })
            that.body = defs
          } else {
            that.body = res.data
            that.found = false
          }
        })  
      }
    }
  }
</script>
<style scoped>
</style>

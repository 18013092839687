import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home'
import Canvas from '@/pages/Canvas'
import Contact from '@/pages/Contact'
import Coffee from '@/pages/Coffee'
import Inquiry from '@/pages/Inquiry'
import BK from '@/pages/BKHome'
import Define from '@/pages/Define'
import Synonym from '@/pages/Synonym'
import Words from '@/pages/Words'
import e404 from '@/components/e404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/bk',
    name: 'BK',
    component: BK,
  },
  {
    path: '/coffee',
    name: 'Coffee',
    component: Coffee
  },
  {
    path: '/inquiry',
    name: 'Inquiry',
    component: Inquiry
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/life',
    name: 'Canvas',
    component: Canvas
  },
  {
    path: '/d/:word',
    name: 'Define',
    component: Define
  },
  {
    path: '/words',
    name: 'Words',
    component: Words
  },
  {
    path: '/s/:word',
    name: 'Synonym',
    component: Synonym
  },
  {
    path: '/404',
    name: 'Error 404',
    component: e404
  },
  {
    path: '*',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
<template>
	<div id="contact-area" class="w-90 center mt3">
		<form v-if="!submitted && !error && !sending" id="contact_form">
			<div class="name">
				<label for="name"></label>
				<input v-model="name" type="text" placeholder="My name is" name="name" id="name_input" required>
			</div>
			<div class="email">
				<label for="email"></label>
				<input v-model="email" type="email" placeholder="My e-mail is" name="email" id="email_input" required>
			</div>
			<div class="telephone">
				<label for="name"></label>
				<input v-model="phone" type="text" placeholder="My number is" name="telephone" id="telephone_input">
			</div>
			<div class="message">
				<label for="message"></label>
				<textarea v-model="message" name="message" placeholder="I'd like to chat about" id="message_input" cols="30" rows="5" required></textarea>
			</div>
			<div @click="onSubmit" class="submit tc">
				<input type="submit" value="Send Message" id="form_button" />
			</div>
		</form>
    <div v-if="submitted" class="w-100 tc mt5 black f3 fw3">
      Thanks for reaching out, I'll be in touch soon!
    </div>
    <div v-if="sending" class="w-100 tc black mt5 f3 fw2 i">
      Sending!
    </div>
    <div v-if="error" class="w-100 tc black mt5 f3 fw2">
      Whoops! <br/>That didn't go as planned... {{ errorMsg }}<br/>send me an email, <span style="font-weight:500">hi@reah.ai</span>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'
export default {
	name: 'ContactForm',
	data() {
		return {
      sending: false,
      submitted: false,
      error: false,
      name: '',   
      phone: '',
      email:'',   
      message:'', 
      errorMsg: ''
    }
  },
  methods: { 
    redirect: function() {
      console.log('redirect home')
      this.$router.push('/')
    }, 
    onSubmit: function(event) {
      if (this.name && this.email && this.message) {
        this.sending = true;
        event.preventDefault();

        let data = {
          "reply_to": this.email,
          "from_name": this.name,
          "to_name": "Reah.AI",
          "message_html": this.message + " \n\n " + this.email + "\n\n " + this.name + "\n\n " + this.phone
        }
        console.log('IN SUBMIT')
        emailjs.send('reah_miyara_reah_me_', 'reah_me', data, 'user_vripzZ6MlykwPKSRL9ejF')
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
            this.sending = false;
            this.submitted = true;
        }, (error) => {
            console.log('FAILED...', error);
            this.sending = false;
            this.error = true
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, input, select, textarea {
  color: #424242;
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

textarea {
  overflow: auto;
}

h1 {
  color: #424242;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
}

.underline {
  border-bottom: solid 1px #424242;
  margin: -0.512em auto;
  width: 80px;
}

.icon_wrapper {
  margin: 50px auto 0;
  width: 100%;
}

.icon {
  display: block;
  fill: #424242;
  height: 50px;
  margin: 0 auto;
  width: 50px;
}

.email {
 float: right;
 width: 45%;
}

input[type='text'], [type='email'], select, textarea {
 background: none;
 border: none;
 border-bottom: solid 1px #424242;
 color: #424242;
 font-size: 1.000em;
 font-weight: 400;
 letter-spacing: 1px;
 margin: 0em 0 1.875em 0;
 padding: 0 0 0.875em 0;
 text-transform: uppercase;
 width: 100%;
 -webkit-box-sizing: border-box;
 -moz-box-sizing: border-box;
 -ms-box-sizing: border-box;
 -o-box-sizing: border-box;
 box-sizing: border-box;
 -webkit-transition: all 0.3s;
 -moz-transition: all 0.3s;
 -ms-transition: all 0.3s;
 -o-transition: all 0.3s;
 transition: all 0.3s;
}

input[type='text']:focus, [type='email']:focus, textarea:focus {
 outline: none;
 padding: 0 0 0.875em 0;
}

.message {
 float: none;
}

.name {
 float: left;
 width: 45%;
}

select {
  background: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-down-32.png') no-repeat right;
  color: #424242;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.subject {
  width: 100%;
}

.telephone {
  width: 100%;
}

textarea {
	line-height: 150%;
	height: 100px;
	resize: none;
 width: 100%;
}

::-webkit-input-placeholder {
	color: grey;
}

:-moz-placeholder { 
	color: grey;
	opacity: 1;
}

::-moz-placeholder {
	color: grey;
	opacity: 1;
}

:-ms-input-placeholder {
	color: grey;
}

#form_button {
  background: none;
  border: solid 1px #424242;
  color: #424242;
  cursor: pointer;
  display: inline-block;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#form_button:hover {
  border: solid 2px #232C33;
  background-color: #424242;
  color: #fff;
}

@media screen and (max-width: 768px) {
  #container {
    margin: 20px auto;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 26px;
  }
  
  .underline {
    width: 68px;
  }
  
  #form_button {
    padding: 15px 25px;
  }
}

@media screen and (max-width: 420px) {
  h1 {
    font-size: 18px;
  }
  
  .icon {
    height: 35px;
    width: 35px;
  }
  
  .underline {
    width: 53px;
  }
  
  input[type='text'], [type='email'], select, textarea {
    font-size: 0.875em;
  }
}
</style>
